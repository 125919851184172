<template>
    <div>
        <form>
            
            <!-- paste from hq below here -->

            <h1>Payments</h1>
            <div>
                <Checkbox
                    class="flex center"
                    id="active"
                    value=1
                    v-model="record_data.active"
                    label="Active"
                    :disabled="!isEditable"
                /></div>
            <div class="flex colx4 wrap">
                <div><Input
                    type="text"
                    class="right-15 block bottom-15"
                    name="payment_type"
                    id="payment_type"
                    placeholder="Payment Type"
                    v-model="record_data.payment_type"
                    label="Payment Type"
                    :disabled="!isEditable"
                /></div>
                <div><Input
                    type="text"
                    class="right-15 block bottom-15"
                    name="client_id"
                    id="client_id"
                    placeholder="Member Id"
                    v-model="record_data.client_id"
                    label="Member Id"
                    :disabled="!isEditable"
                /></div>
                <div><Input
                    type="text"
                    class="right-15 block bottom-15"
                    name="payment_amount"
                    id="payment_amount"
                    placeholder="Payment Amount"
                    v-model="record_data.payment_amount"
                    label="Payment Amount"
                    :disabled="!isEditable"
                /></div>
                <div><Input
                    type="text"
                    class="right-15 block bottom-15"
                    name="stripe_payment_intent_id"
                    id="stripe_payment_intent_id"
                    placeholder="Stripe Payment Intent Id"
                    v-model="record_data.stripe_payment_intent_id"
                    label="Stripe Payment Intent Id"
                    :disabled="!isEditable"
                /></div>
                <div><Input
                    type="text"
                    class="right-15 block bottom-15"
                    name="checknum"
                    id="checknum"
                    placeholder="Check #"
                    v-model="record_data.checknum"
                    label="Check #"
                    :disabled="!isEditable"
                /></div>
                <div><Input
                    type="text"
                    class="right-15 block bottom-15"
                    name="comment"
                    id="comment"
                    placeholder="Comment"
                    v-model="record_data.comment"
                    label="Comment"
                    :disabled="!isEditable"
                /></div>

                <!--form_extra-->
            </div>
<!-- <button class="bottom-20">Refund</button>-->
<!-- button removed for BM-905 -->
        </form>
    </div>
</template>
<script>
    const api_root = 'payments'

    export default {
        name: 'PaymentDetail',
        props: {
            record_id: [String, Number],
        },
        data() {
            return {
                record_data : {
                    active : 0,
                    payment_type : '',
                    client_id : 1,
                    payment_amount : 1,
                    stripe_payment_intent_id : '',
                    checknum : '',
                    comment : '',
                    // fields from hq
                },
                isEditable : 1,
                record_actions : {
                    getRecordDetails: (id) => `/${api_root}/${id}`,
                }
            }
        },
        methods: {
            async init() {
                if (!(this.record_id === 'new')) {
                    const res = await this.$api.get(this.record_actions.getRecordDetails(this.record_id))

                    if (res.status === 404 || res.status === 500) {
                        this.$toasted.error('Could not find record')
                        return this.$router.replace({ name: 'NotFound' })
                    } else {
                        this.record_data = res.data.info[0]
                    }
                }
            },
            async validateForm() {
                const isValid = 1 // await this.$refs.form.validate()

                if (!isValid) {
                    this.$toasted.error('Please fill out all required fields.')
                }

                return isValid
            },
        },
        async created() {
            await this.init()
        },
        watch: {
            async $route() {
                await this.init()
            },
        },
    }
</script>
